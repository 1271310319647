
import React, { useState,useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faCog, faEnvelopeOpen,  faSignOutAlt, faUserShield } from "@fortawesome/free-solid-svg-icons";
import { faUserCircle } from "@fortawesome/free-regular-svg-icons";
import { Row, Col, Nav, Form, Image, Navbar, Dropdown, Container, ListGroup, Button } from '@themesberg/react-bootstrap';
import UploadModal from "../components/UploadModal";
import NOTIFICATIONS_DATA from "../data/notifications";
import Profile3 from "../assets/img/team/profile-picture-3.jpg";
import { useHistory } from 'react-router-dom';
import { Routes } from "../routes";
import '../scss/darkMode.css';

import DarkModeToggle from "./DarkModeToggle";

export default (props) => {
  const [notifications, setNotifications] = useState(NOTIFICATIONS_DATA);
  //const areNotificationsRead = notifications.reduce((acc, notif) => acc && notif.read, true);
  const [showDefault, setShowDefault] = useState(false);
  const handleClose = () => setShowDefault(false);
  // const [theme, setTheme] = useState('light');
  // const toggleTheme = () => {
  //   if (theme === 'light') {
  //     setTheme('dark');
  //   } else {
  //     setTheme('light');
  //   }
  // };

  // useEffect(() => {
  //   document.body.className = theme;
  // }, [theme]);
  // const markNotificationsAsRead = () => {
  //   setTimeout(() => {
  //     setNotifications(notifications.map(n => ({ ...n, read: true })));
  //   }, 300);
  // };
  const [flightName, setFlightName] = useState('');
  const [file, setFile] = useState(null);
  const handleFileChange = (event) => {
    // Handle file input change and update the state
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const handleFlightNameChange = (event) => {
    // Handle flight name input change and update the state
    setFlightName(event.target.value);
  };
  const history = useHistory();
  const handleUpload = () => {
    // Prepare the form data
    const formData = new FormData();
    formData.append('csvFile', file);
    formData.append('FlightName', flightName);
    setLoading(true);
    // Make a POST request to localhost:3333/upload
    fetch('http://localhost:3333/upload', {
      method: 'POST',
      body: formData,
    })
      .then(response => response.json())
      .then(data => {
        // Handle response data if needed
        console.log(data);
        setSuccess(true);
        setLoading(false);
        setFlightName('');
        setFile(null);
        handleClose();
        history.replace(Routes.Maps.path);
        // setTimeout(() => {
        //   // Use window.location.href to force a reload
        //   window.location.href = 'http://localhost:3000/#/maps';
        // }, 0);
            // Check if already on Routes.Maps.Path
        // console.log('History PathName',history.location.pathname)    
        // console.log('Routes.Maps.Path',Routes.Maps.Path)
        //   // Check if already on Routes.Maps.Path
        // const currentPath = history.location.pathname;
        // const targetPath = Routes.Maps.Path;

        // if (currentPath !== targetPath) {
        //     
        // } else {
           
        // }
      })
      .catch(error => {
        // Handle errors
        console.error(error);
        setError(error.message || 'Failed to upload data. Please try again.');
        setLoading(false);
      });
  };
  const csvClick = () => {
    // Prepare the form data
    setShowDefault(true);
    setSuccess(false);
    setError(false);
  };
  const shouldRenderButton = history.location.pathname !== Routes.Maps.path;

  const Notification = (props) => {
    const { link, sender, image, time, message, read = false } = props;
    const readClassName = read ? "" : "text-danger";

    return (
      <ListGroup.Item action href={link} className="border-bottom border-light">
        <Row className="align-items-center">
          <Col className="col-auto">
            <Image src={image} className="user-avatar lg-avatar rounded-circle" />
          </Col>
          <Col className="ps-0 ms--2">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h4 className="h6 mb-0 text-small">{sender}</h4>
              </div>
              <div className="text-end">
                <small className={readClassName}>{time}</small>
              </div>
            </div>
            <p className="font-small mt-1 mb-0">{message}</p>
          </Col>
        </Row>
      </ListGroup.Item>
    );
  };

  return (
    <Navbar variant="light" expanded className="ps-0 pe-2 pb-0 navbar-theme">
      <Container fluid className="px-0">
        <div className="d-flex justify-content-between w-100">
          <div className="d-flex align-items-center">
            <Form className="">
              <Form.Group id="csvUpload">
              {shouldRenderButton && (
        <Button variant="primary" className="my-3" onClick={csvClick}>
          CSV Upload
        </Button> 
      )}
      {/* <button onClick={toggleTheme}>Toggle Theme</button> */}
              </Form.Group>
            </Form>
          </div>
          <Nav className="align-items-center">
          <DarkModeToggle />


            <Dropdown as={Nav.Item}>
              <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                <div className="media d-flex align-items-center">
                  <Image src={Profile3} className="user-avatar md-avatar rounded-circle" />
                  <div className="media-body ms-2 text-dark align-items-center d-none d-lg-block">
                   
                  </div>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
                <Dropdown.Item className="fw-bold">
                  <FontAwesomeIcon icon={faUserCircle} className="me-2" /> My Profile
                </Dropdown.Item>
                <Dropdown.Item className="fw-bold">
                  <FontAwesomeIcon icon={faCog} className="me-2" /> Settings
                </Dropdown.Item>
                <Dropdown.Item className="fw-bold">
                  <FontAwesomeIcon icon={faEnvelopeOpen} className="me-2" /> Messages
                </Dropdown.Item>
                <Dropdown.Item className="fw-bold">
                  <FontAwesomeIcon icon={faUserShield} className="me-2" /> Support
                </Dropdown.Item>

                <Dropdown.Divider />

                <Dropdown.Item className="fw-bold">
                  <FontAwesomeIcon icon={faSignOutAlt} className="text-danger me-2" /> Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </div>
      </Container>
      <UploadModal
        handleClose={handleClose}
        handleFileChange={handleFileChange}
        handleFlightNameChange={handleFlightNameChange}
        handleUpload={handleUpload}
        flightName={flightName}
        showDefault={showDefault}
        loading={loading}
        success={success}
        error={error}

      />
    </Navbar>

  );
};
