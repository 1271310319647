// import { Navbar, Container, Nav, NavDropdown, Image } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Image from 'react-bootstrap/Image'
import { HashLink } from 'react-router-hash-link';
import { Routes } from "../routes";
import AxoltlLogo from "../assets/img/AxoltlLogo.png";

const NavbarComponent = () => {

  return (
<Navbar variant="dark" expand="lg" bg="dark" className="navbar-transparent navbar-theme-primary sticky-top">
      <Container fluid className="p-0">
      <Navbar.Brand as={HashLink} to={Routes.Presentation.path} className="me-lg-3 d-flex align-items-center">
          <Image src={AxoltlLogo} style={{ width: '50px', height: 'auto' }} />
          <span className="ms-2  text-tertiary ">AXOLTL</span>
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="navbar-default-primary navbarScroll" />

        <Navbar.Collapse id="navbar-default-primary" className="text-tertiary ">
          <Nav className="navbar-nav-hover align-items-lg-center">
            <Nav.Link as={HashLink} to={Routes.Presentation.path}>
            <span className="ms-2 text-tertiary d-md-inline"> Home</span>
            </Nav.Link>
            <Nav.Link as={HashLink} to={Routes.OurVision.path}>
            <span className="ms-2 text-tertiary d-md-inline"> Our Vision</span>
            </Nav.Link>
            <NavDropdown title={<span className="ms-2 text-tertiary d-md-inline">Solutions</span> } id="basic-nav-dropdown">
              <NavDropdown.Item as={HashLink} to={Routes.AeroTrak.path}>
              <span className="ms-2 text-tertiary d-md-inline">AeroTrak</span>
              </NavDropdown.Item>
              <NavDropdown.Item as={HashLink} to={Routes.TeleMAPP.path}>
              <span className="ms-2 text-tertiary d-md-inline">TeleMAPP</span> 
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    
  );
};

// function BasicExample() {
//   return (
//     <Navbar  variant="dark" expand="lg" bg="dark" className="bg-body-tertiary">
//       <Container >
      <Navbar.Brand as={HashLink} to={Routes.Presentation.path} className="me-lg-3 d-flex align-items-center">
          <Image src={AxoltlLogo} style={{ width: '50px', height: 'auto' }} />
          <span className="ms-2 brand-text d-none d-md-inline">AXOLTL</span>
        </Navbar.Brand>
//         <Navbar.Toggle aria-controls="basic-navbar-nav" />
//         <Navbar.Collapse id="basic-navbar-nav">
//           <Nav className="me-auto">
//             <Nav.Link href="#home">Home</Nav.Link>
//             <Nav.Link href="#link">Link</Nav.Link>
//             <NavDropdown title="Dropdown" id="basic-nav-dropdown">
//               <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
//               <NavDropdown.Item href="#action/3.2">
//                 Another action
//               </NavDropdown.Item>
//               <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
//               <NavDropdown.Divider />
//               <NavDropdown.Item href="#action/3.4">
//                 Separated link
//               </NavDropdown.Item>
//             </NavDropdown>
//           </Nav>
//         </Navbar.Collapse>
//       </Container>
//     </Navbar>
//   );
// }

// export default BasicExample;

export default NavbarComponent;
