import React from 'react';
import HDash from "../assets/img/brand/hdash.PNG";
import OverviewTab from "../assets/img/brand/OverviewTab.PNG";
import CEO from "../assets/img/brand/ceo.PNG";
import axolotl from  "../assets/img/brand/axolotl.PNG";
import ObjDet from "../assets/img/brand/ObjDet.JPG"; 
import FlightSimulator from "../assets/img/brand/cessna-home-flight-simulator.jpg"; 
import { Col, Row,  Image, Button, Container } from '@themesberg/react-bootstrap';
import { useHistory } from 'react-router-dom';
const AxoltlHome = () => {
  const containerStyle = {
    position: 'relative',
    width: '100%',
    height: '60vh',
    backgroundImage: `url(${HDash})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    color: '#ffffff', // Light colored text
    textAlign: 'left',
    padding: '20px',
  };

  const logoStyle = {
    position: 'absolute',
    top: '20px',
    left: '20px',
    width: '50px', // Adjust the width of your logo as needed
    height: 'auto', // Adjust the height of your logo as needed
  };

  const textContainerStyle = {
    position: 'absolute',
    bottom: '100px',
    left: '20px',
    width: '40%',
  };

  // Media query for screens smaller than 768 pixels (adjust as needed)
  const mediaQueryStyle = {
    width: '80%', // Adjust the width for smaller screens
  };

  return (
    <div style={containerStyle}>
      <div style={{ ...textContainerStyle, ...mediaQueryStyle }}>
      <h1>Empowering the modern era of trajectory-based operations through <span style={{ color: '#278A78' }}>AI</span>.</h1>
<p><span style={{ color: '#278A78' }}>AXOLTL</span> augments aerospace professionals and flight operations with adaptive, data-driven AI solutions.</p>
      </div>
    </div>
  );

  
};




const TwoColumnComponent = () => {
  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    
    position: 'relative',
    backgroundColor: '#f0f0f0',
    padding: '20px',
    boxSizing: 'border-box',
    overflow: 'hidden',
  };

  const headerColumnStyle = {
    flex: '1',
  };

  const contentColumnStyle = {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  };


  // Media query for screens smaller than 768 pixels
  const isMobile = window.innerWidth <= 768;

  return (
    <div style={{ ...containerStyle }}>
      {isMobile ? (
        <div>
          <div style={headerColumnStyle}>
          <h1>Soar into the next generation of <span style={{ color: '#278A78' }}>pilot training </span> and <span style={{ color: '#278A78' }}>flight operations </span>!</h1>
          </div>
          <div style={contentColumnStyle}>
          <p> <span style={{ color: '#278A78' }}>AXOLTL LLC </span> is a service-disabled veteran-owned small business (SDVOSB) committed to advancing the frontiers of innovation within the aerospace domain. Built upon the trademark characteristics of our  <span style={{ color: '#278A78' }}></span>axolotl namesake, our Artificial Intelligence solutions are developed to be  <span style={{ color: '#278A78' }}>adaptable</span> across dynamic environments while achieving  <span style={{ color: '#278A78' }}>resiliency</span> through continual optimization.
              <br />
              <br />
              Our mission is to empower the aerospace industry with tailored, intelligent technologies that enhance operational efficiency, optimize decision-making processes, and foster a safer and more secure future. Through a relentless pursuit of excellence, we aim to be the trusted partner for aerospace enterprises seeking to navigate the complexities of the modern digital landscape. Our adaptive AI/ML solutions are designed to evolve alongside the dynamic challenges of the industry, ensuring that our clients stay at the forefront of technological innovation. </p>
          </div>
        </div>
      ) : (
        <Row>
          <Col style={headerColumnStyle}>
          <h1>Soar into the next generation of <span style={{ color: '#278A78' }}>pilot training </span> and <span style={{ color: '#278A78' }}>flight operations </span>!</h1>
          </Col>
          <Col style={contentColumnStyle}>
          <p> <span style={{ color: '#278A78' }}>AXOLTL LLC </span> is a service-disabled veteran-owned small business (SDVOSB) committed to advancing the frontiers of innovation within the aerospace domain. Built upon the trademark characteristics of our  <span style={{ color: '#278A78' }}></span>axolotl namesake, our Artificial Intelligence solutions are developed to be  <span style={{ color: '#278A78' }}>adaptable</span> across dynamic environments while achieving  <span style={{ color: '#278A78' }}>resiliency</span> through continual optimization.
              <br />
              <br />
              Our mission is to empower the aerospace industry with tailored, intelligent technologies that enhance operational efficiency, optimize decision-making processes, and foster a safer and more secure future. Through a relentless pursuit of excellence, we aim to be the trusted partner for aerospace enterprises seeking to navigate the complexities of the modern digital landscape. Our adaptive AI/ML solutions are designed to evolve alongside the dynamic challenges of the industry, ensuring that our clients stay at the forefront of technological innovation. </p>
          </Col>
        </Row>
      )}
    </div>
  );
};




const ImageColumnComponent = () => {
  const history = useHistory();

  const handleLearnMoreClickAeroTrak = () => {
    // Navigate to the desired page (replace '/learn-more' with your actual route)
    history.push('/AeroTrak');
  };
  const handleLearnMoreClickTeleMAPP = () => {
    // Navigate to the desired page (replace '/learn-more' with your actual route)
    history.push('/TeleMAPP');
  };
  const imageColumnStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    //justifyContent: 'center',
    height: '100%',
    padding: '20px',
  };
  const columnStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    padding: '20px',
  };
  const imageStyle = {
    width: '80%',
    marginBottom: '20px',
  };
  const buttonStyle = {
    display: 'flex',
    marginBottom: '20px',
    alignItems: 'center',
    justifyContent: 'center',
  };
  return (
     <div>
      <Row >
        <Col xs={12} md={6} style={{ ...imageColumnStyle, textAlign: 'left', alighnItems: 'top' }}>
          <Image src={FlightSimulator} alt="Top Left Image" style={imageStyle} />
        </Col>
        <Col xs={12} md={6} style={{ ...imageColumnStyle, textAlign: 'left', alighnItems: 'right' }}>
          <h1><span style={{ color: '#278A78' }}>TeleMAPP</span></h1>
          <p style={{ fontSize: '1.2rem' }}>Our aerospace telemetry analytics solution leverages existing on-board and flight simulator telemetry recorders to build adaptive analytics to meet customer requirements. We use the latest techniques to enhance and model post-flight analytics, Flight Maneuver Recognition (FMR), and operational safety monitoring.</p>
          
          <div style={columnStyle}>
          <Button onClick={handleLearnMoreClickTeleMAPP} variant="tertiary" style={buttonStyle}>
            Learn More
          </Button>
          </div>
        </Col>
      </Row>
      <Row >
        <Col xs={12} md={6} style={{ ...imageColumnStyle, textAlign: 'left', alighnItems: 'top' }}>
         
          <h1><span style={{ color: '#278A78' }}>AeroTrak</span></h1>
          <p style={{ fontSize: '1.2rem' }}>Improve pilot situational awareness and safety through our customizable computer vision solutions for detecting, tracking, and classifying aircraft. Our multi-object tracking platform can be adapted across fixed observation points with cloud integration and on-board aircraft with NVIDIA hardware for cockpit integration.</p>
          <div style={columnStyle}>
          <Button onClick={handleLearnMoreClickAeroTrak} variant="tertiary" style={buttonStyle}>
            Learn More
          </Button>
          </div>
        </Col>
        <Col xs={12} md={6} style={{ ...imageColumnStyle, textAlign: 'left', alighnItems: 'right' }}>
      
          <Image src={ObjDet} alt="Bottom Right Image" style={imageStyle} />
        </Col>
      </Row>
      </div>


  );
};

const ContactUsComponent = () => {
  return (
    <Container className="text-center my-5">
      <h1>Would you like to know <span style={{ color: '#278A78' }}>more</span>?</h1>
      <p>
      We understand that every business is unique, and a one-size-fits-all approach simply won’t cut it. That’s why we take the time to understand your specific challenges, goals, and vision. Our team is poised and ready to collaborate with you to turn our adaptive solutions into your competitive edge. 
      </p>
      {/* <Button variant="tertiary">Contact Us Today</Button> */}
      <span variant="tertiary">Contact Us Today!</span>
    </Container>
  );
};

const TelemappComponent = () => {
  return (
    <Container className="text-center my-5">
       <img src={OverviewTab}  />
       <hr className=" my-5" />
      <h1><span style={{ color: '#278A78' }}>TeleMAPP</span></h1>
      <p>
      The Telemetry Maneuver Analytics & Positioning Platform (TeleMAPP) is your gateway to a new era of aviation excellence, offering a state-of-the-art aircraft telemetry analytics platform that goes beyond summary statistics. Designed to elevate your flight operations, our platform specializes in Flight Maneuver Recognition (FMR), anomaly detection, and comprehensive post-flight analytics.
<br/>
<br/>
TeleMAPP meticulously analyzes telemetry data to identify and categorize a wide range of flight maneuvers by employing the latest in deep learning technology. From standard procedures to complex aerobatics, our platform provides detailed insights into every phase of your flight, empowering pilots and operators with invaluable information.
<br/>
<br/>
Safety is paramount in aviation, and TeleMAPP takes it seriously. Our anomaly detection algorithms are designed to identify irregularities or deviations from expected flight patterns. Whether it’s unexpected changes in altitude, speed variations, or unusual system behaviors, TeleMAPP keeps a vigilant eye on your telemetry data, ensuring that potential issues are flagged for immediate attention.
<br/>
<br/>
TeleMAPP boasts an intuitive, user-friendly interface that allows both seasoned aviation professionals and newcomers to effortlessly explore and interpret flight data. Visualize maneuvers, detect anomalies, and extract meaningful conclusions with just a few clicks. 
<br/>
<br/>
Ready to take flight analytics to new heights? Contact us today to schedule a demo and see TeleMAPP in action!      </p>
  
      
    </Container>
  );
};
const AeroTrakComponent = () => {
  return (
    <Container className="text-center my-5">
       <img src={ObjDet}  />
       <hr className="bg-gray my-5" />
      <h1><span style={{ color: '#278A78' }}>AeroTrak</span></h1>
    <p>
    In the dynamic realm of aviation, precise object tracking is not just a necessity; it’s a game-changer. AeroTrak is a cutting-edge multi-object tracking platform specifically engineered for onboard aircraft tracking of other aircraft, drones, and more. Elevate your situational awareness with this state-of-the-art technology built on the latest in deep learning computer vision algorithms.
    <br/>
<br/>
Experience real-time tracking of surrounding aircraft, drones, and relevant objects, empowering pilots with instant, accurate information. This advanced tracking capability enhances collision avoidance and overall flight safety across every level of flight operation.
<br/>
<br/>
Whether navigating busy airspace, monitoring drone activity, or ensuring safe separation from other aircraft, AeroTrak adapts to your specific requirements. Its robust algorithms can handle diverse environmental conditions, ensuring reliable tracking performance in different lighting, weather, and operational scenarios.
<br/>
<br/>
Ready to experience the future of onboard aircraft tracking? Contact us today to explore how AeroTrak can revolutionize your aviation operations.
    </p>
    </Container>
  );
};
const OurVisionComponent = () => {
  const imageColumnStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    //justifyContent: 'center',
    height: '100%',
    padding: '20px',
  };
  const columnStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    padding: '20px',
  };
  const imageStyle = {
    width: '80%',
    marginBottom: '20px',
  };
  const buttonStyle = {
    display: 'flex',
    marginBottom: '20px',
    alignItems: 'center',
    justifyContent: 'center',
  };
  return (
    <div>
    <Container className="text-center my-5">
      <h1>
      <span style={{ color: '#278A78' }}>A</span>daptive <span style={{ color: '#278A78' }}>X</span> <span style={{ color: '#278A78' }}>O</span>mni-<span style={{ color: '#278A78' }}>L</span>ytic <span style={{ color: '#278A78' }}>T</span>echnologies & <span style={{ color: '#278A78' }}>L</span>abs
      </h1>
      <h1>
        (<span style={{ color: '#278A78' }}>AXOLTL</span>)
      </h1>
    <Row >
      <Col xs={12} md={6} style={{ ...imageColumnStyle, textAlign: 'left', alighnItems: 'top' }}>
        <Image src={axolotl} alt="Top Left Image" style={imageStyle} />
      </Col>
      <Col xs={12} md={6} style={{ ...imageColumnStyle, textAlign: 'left', alighnItems: 'right' }}>
        <h1><span style={{ color: '#278A78' }}>Company Overview</span></h1>
        <p>
        AXOLTL LLC is a Service-Disabled Veteran-Owned Small Business committed to delivering cutting-edge AI solutions that not only meet the challenges of today but anticipate the demands of tomorrow. Our team of visionary experts has over 20+ years combined experience in developing innovative solutions for both federal and commercial customers.
        </p>
      </Col>
     
    </Row>
  

    <Row >
       
        <h1><span style={{ color: '#278A78' }}>Our Mission</span></h1>
        <p>
        Our mission is to empower the aerospace industry with tailored, intelligent technologies that enhance operational efficiency, optimize decision-making processes, and foster a safer and more secure future. Through a relentless pursuit of excellence, we aim to be the trusted partner for aerospace enterprises seeking to navigate the complexities of the modern digital landscape. Our adaptive AI/ML solutions are designed to evolve alongside the dynamic challenges of the industry, ensuring that our clients stay at the forefront of technological innovation. 
        </p>   
        <div style={columnStyle}>

        </div>


    </Row>
    </Container>
    </div>
  );
};
const ImageWithTextBox = () => {
  return (

<Row className="justify-content-center">
  <Col className="p-0">
    <div
      style={{
        backgroundImage: `url(${CEO})`, // Replace with your image URL
        backgroundSize: 'cover',
        width: '100%',
        overflow: 'hidden', // Prevents the image from overflowing on small screens
      }}
    >
      {/* Use an img element with the img-fluid class for responsiveness */}
      <img
        src={CEO}
        alt="CEO"
        className="img-fluid"
        style={{ width: '100%', height: 'auto' }}
      />
    </div>
  </Col>
</Row>




  );
};

// export default AxoltlHome;
export { AxoltlHome, TwoColumnComponent, ImageColumnComponent, ContactUsComponent, TelemappComponent, AeroTrakComponent, OurVisionComponent, ImageWithTextBox};