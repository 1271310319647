
import AxoltlLogo from "../assets/img/AxoltlLogo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SDVOC from "../assets/img/brand/Service-Disabled Veteran-Owned-Certified.jpg"; 
import {  faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faLinkedin, faInstagram} from "@fortawesome/free-brands-svg-icons";
import { Col, Row, Card, Image  } from '@themesberg/react-bootstrap';
const FooterComponent = () => {
  return (
    <footer className="footer py-6">

    <Row className="text-center">
      <Col xs={12} md={1} className="mb-4 mb-md-0">
        <Image src={SDVOC} />
      </Col>
      <Col xs={12} md={1} className="mb-4 mb-md-0">
       
      </Col>
      <Col xs={12} md={2} className="mb-5 mb-md-0">
        <span style={{ color: '#278A78' }} className="h5">Identifiers</span>
        <ul className="links-vertical mt-2">
          <li><Card.Link target="_blank">DUNS: 062309032</Card.Link></li>
          <li><Card.Link target="_blank">SAM: J7X2WKXLW197</Card.Link></li>
          <li><Card.Link target="_blank">CAGE: 9Q864</Card.Link></li>
          <li><Card.Link target="_blank">NAICS: 541715</Card.Link></li>
        </ul>
      </Col>
  
      <Col xs={12} md={4} className="mb-5 mb-md-0">
        <span style={{ color: '#278A78' }} className="h5">Contact Info</span>
        <ul className="links-vertical mt-2">
          <li>
            <FontAwesomeIcon icon={faEnvelope} /> <span style={{ color: '#278A78' }}>Email</span> info@axoltl.net
          </li>
          {/* <li>
            <FontAwesomeIcon icon={faPhone} /> <span style={{ color: '#278A78' }}>Phone</span> (808)-216-0533
          </li> */}
        </ul>
      </Col>
  
      <Col xs={12} md={4} className="mb-5 mb-md-0">
        <span style={{ color: '#278A78' }} className="h5">Socials</span>
        <ul className="links-vertical mt-2">
          <li>
            <Card.Link target="_blank" href="https://www.facebook.com/profile.php?id=61551022437585">
              <FontAwesomeIcon icon={faFacebook} /> <span style={{ color: '#278A78' }}>Facebook</span>
            </Card.Link>
          </li>
          <li>
            <Card.Link target="_blank" href="https://www.instagram.com/axoltl.llc/">
              <FontAwesomeIcon icon={faInstagram} /> <span style={{ color: '#278A78' }}>Instagram</span>
            </Card.Link>
          </li>
          <li>
            <Card.Link target="_blank" href="https://www.linkedin.com/company/98651893/">
              <FontAwesomeIcon icon={faLinkedin} /> <span style={{ color: '#278A78' }}>LinkedIn</span>
            </Card.Link>
          </li>
        </ul>
      </Col>
    </Row>
  
    <hr className="bg-gray my-5" />
  
    <Row>
      <Col className="mb-md-2 text-center">
        <Card.Link href="https://axoltl.businesscredentials.info" target="_blank" className="d-flex justify-content-center">
          <Image src={AxoltlLogo} height={35} className="d-block mx-auto mb-3" alt="Themesberg Logo" />
        </Card.Link>
        <div className="d-flex text-center justify-content-center align-items-center" role="contentinfo">
          <p className="font-weight-normal font-small mb-0">Copyright © AXOLTL LLC. <span className="current-year">2023</span>. All rights reserved.</p>
        </div>
      </Col>
    </Row>
  
  </footer>
  );
};

export default FooterComponent;
