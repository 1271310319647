import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NavbarComponent from "../components/AXOLTLNavbar";
import FooterComponent from "../components/StaticFooter";
import { faExternalLinkAlt,  faFolder } from "@fortawesome/free-solid-svg-icons";
import { Col, Card, Image, Tooltip, OverlayTrigger } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';
import '../scss/backgroundWhite.css';
import {AxoltlHome, TwoColumnComponent, ImageColumnComponent, ContactUsComponent} from "../components/AXOLTLHome";

export default () => {
  const PagePreview = (props) => {
    const { name, image, link } = props;

    return (
      <Col xs={6} className="mb-5">
        <Card.Link as={Link} to={link} className="page-preview page-preview-lg scale-up-hover-2">
          <Image src={AxoltlHome} className="shadow-lg rounded scale" alt="Dashboard page preview" />

          <div className="text-center show-on-hover">
            <h6 className="m-0 text-center text-white">
              {name} <FontAwesomeIcon icon={faExternalLinkAlt} className="ms-2" />
            </h6>
          </div>
        </Card.Link>
      </Col>
    );
  };

  
  const Feature = (props) => {
    const { title, description, icon } = props;

    return (
      <Col xs={12} sm={6} lg={3}>
        <Card className="bg-white shadow-soft text-primary rounded mb-4">
          <div className="px-3 px-lg-4 py-5 text-center">
            <span className="icon icon-lg mb-4">
              <FontAwesomeIcon icon={icon} />
            </span>
            <h5 className="fw-bold text-primary">{title}</h5>
            <p>{description}</p>
          </div>
        </Card>
      </Col>
    );
  };

  const FolderItem = (props) => {
    const { name, icon, tooltip, iconColor } = props;
    const color = iconColor ? `text-${iconColor}` : "";

    return (
      <OverlayTrigger
        trigger={['hover', 'focus']}
        placement="left"
        overlay={<Tooltip>{tooltip}</Tooltip>}
      >
        <li data-toggle="tooltip" data-placement="left" title="Main folder that you will be working with">
          <FontAwesomeIcon icon={icon ? icon : faFolder} className={`${color} me-2`} /> {name}
        </li>
      </OverlayTrigger>
    );
  };

  return (
    <>
   <NavbarComponent/>
      <section className="text-white" id="home">
      <AxoltlHome />
      </section>
      <section >
        <TwoColumnComponent />
      </section>  
      <section className="section section-lg bg-primary text-white">
         <ImageColumnComponent/>
      </section>
      <section className="" id="home">
     <ContactUsComponent/>
      </section>
      <hr className="bg-gray my-5" />
      <FooterComponent/>
    </>
  );
};
