import React from "react";  
import useDarkMode from "use-dark-mode";

const DarkModeToggle = () => {
  const { value, toggle } = useDarkMode(false);
  return (
    <button type="button" onClick={toggle}>
      {value ? "☀" : "☾"}
    </button>
  );
}

export default DarkModeToggle;
