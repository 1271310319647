import React, { useState } from 'react';
import { Row, Col, Nav, Form, Image, Navbar, Dropdown, Container, ListGroup, InputGroup, Button, Modal,Alert } from '@themesberg/react-bootstrap';

const UploadModal = ({ handleClose, handleFileChange, handleFlightNameChange, handleUpload, flightName,showDefault, loading,success, error }) => {



  return (
<React.Fragment>
  <Modal as={Modal.Dialog} centered show={showDefault} onHide={handleClose}>
    {/* Modal Content */}
    <Modal.Body style={{ padding: '20px' }}> {/* Add padding here */}
      <Form.Label>Flight Data</Form.Label>
      <Form.Control type="file" onChange={handleFileChange} />
      <Form.Label>Flight Name</Form.Label>
      <Form.Control
        readOnly={false}
        type="text"
        placeholder="Not selected"
        value={flightName}
        onChange={handleFlightNameChange}
      />
      {/* Modal Buttons */}
      {/* Upload Button */}
      <Button variant="primary" disabled={loading || success} onClick={handleUpload}>
        {loading ? 'Uploading...' : 'Upload'}
      </Button>
      {/* Success Message */}
      {success && <Alert variant="success" className="mt-2">Data uploaded successfully!</Alert>}

      {/* Error Message */}
      {error && <Alert variant="danger" className="mt-2">{error}</Alert>}

      {/* Close Button */}
      <Button variant="link" className="text-gray ms-auto" onClick={handleClose}>
        Close
      </Button>
    </Modal.Body>
  </Modal>
</React.Fragment>

  );
};

export default UploadModal;
